import { Box, Typography } from "@mui/material";
import GradeIcon from "@mui/icons-material/Grade";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import Moment from "react-moment";
import { useEffect, useState } from "react";
const main = {
  position: "absolute",
  left: "0%",
  bottom: "0%",
  borderRadius: "10px",
  p: 5,
  outline: "none",
  display: "flex",
  justifyContent: "space-between",
};

export default function EconomicNewsModal({ handleClose, curEconomicNews }) {
  const [time, setTime] = useState(false);

  const padZero = (value) => {
    return value < 10 ? `0${value}` : value;
  };
  useEffect(() => {
    const timer = setInterval(() => {
      const currentDate = new Date(new Date().toUTCString());
      let utcDate = new Date(curEconomicNews[0].date);
      const objDate = new Date(
        utcDate?.toLocaleString("en-US", { timeZone: "UTC" })
      );
      const differenceMs = objDate.getTime() - currentDate.getTime();
      const differenceMinutes = Math.floor(differenceMs / (1000 * 60));
      const differenceSeconds = Math.floor((differenceMs % (1000 * 60)) / 1000);

      if (differenceMinutes > 5) {
        setTime(false);
      } else {
        setTime(`${padZero(differenceMinutes)}:${padZero(differenceSeconds)}`);
      }
      if (differenceSeconds < 0) {
        handleClose();
        // setTime(`${padZero(0)}:${padZero(0)}`);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [curEconomicNews]);
  const modalWidth = window.innerWidth * 0.6;
  return (
    <Box sx={main}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          gap: "5px",
          color: "#FFFFFF",
        }}
      >
        <Box
          sx={{
            p: 1,
            border: "1px solid #FFFFFF",
            borderRadius: "50%",
            zIndex: 100,
          }}
        >
          <Box
            sx={{
              background: "linear-gradient(180deg, #FFC807 0%, #D99512 100%)",
              height: "7vw",
              width: "7vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              borderRadius: "50%",
              flexDirection: "column",
              border: "3px solid #FFFFFF",
              color: "#161413",
            }}
          >
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: time ? "1.3vw" : "1.1vw",
                letterSpacing: "1px",
              }}
            >
              {time ? (
                time
              ) : (
                <>
                  Today,
                  <br />
                  {new Date(curEconomicNews[0]?.date).toLocaleTimeString(
                    "en-US",
                    {
                      timeZone: "UTC",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  )}
                </>
              )}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            ml: -7,
            clipPath: " polygon(0 0, 100% 0, 93% 100%, 0 100%)",
            width: `${modalWidth}px`,
          }}
        >
          <Box
            sx={{
              height: "3.4vw",
              background:
                "linear-gradient(14.01deg, #CF201E 16.36%, #911619 90.94%)",
              pl: 8,
              pr: 4,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 5,
            }}
          >
            <Typography sx={{ fontSize: "1.5vw", fontWeight: "Bold" }}>
              {curEconomicNews[0]?.news}
            </Typography>{" "}
            <CancelPresentationIcon
              sx={{
                color: "white",
                fontSize: "1.5vw",
                cursor: "pointer",
                mt: { lg: -2, xs: -1 },
              }}
              onClick={handleClose}
            />
          </Box>
          <Box
            sx={{
              height: "3vw",
              background:
                "linear-gradient(90deg, #ACA8A8 0%, #E6E6E6 32.5%, #DCDCDC 73.5%, #ACA9A9 100%)",

              px: 8,
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1vw",
                fontWeight: "Bold",
                background:
                  curEconomicNews[0]?.priority === 3
                    ? "#C11E1D"
                    : curEconomicNews[0]?.priority == 2
                    ? "#E3A210"
                    : "#10C02D",
                px: 2,
                height: "2vw",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {curEconomicNews[0]?.priority === 3
                ? "High"
                : curEconomicNews[0]?.priority == 2
                ? "Medium"
                : "Low"}
            </Typography>
            <Box sx={{ display: "flex", gap: "10px" }}>
              {[1, 2, 3].map((priorityValue) => (
                <>
                  {curEconomicNews[0]?.priority >= priorityValue ? (
                    <GradeIcon
                      key={priorityValue}
                      sx={{
                        color:
                          curEconomicNews[0]?.priority === 3
                            ? "#C11E1D"
                            : curEconomicNews[0]?.priority == 2
                            ? "#E3A210"
                            : "#10C02D",
                        fontSize: "1.7vw",
                      }}
                    />
                  ) : (
                    <GradeIcon
                      key={priorityValue}
                      sx={{
                        color: "#B8B8B8",
                        fontSize: "1.7vw",
                      }}
                    />
                  )}
                </>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
